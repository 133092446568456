@import '../../themes/variables';

.card-deck-artes {
  display: flex;
  position: static !important;
  flex-direction: row;
  justify-content: flex-start;
  flex-flow: wrap;

  .card-artes {
    border-radius: 20px !important;
    max-width: 240px;
    min-width: 200px;
    border: 0px solid $black !important;
    background-color: $gray-light !important;
    padding-bottom: 15px;
    margin: 25px 15px 15px 15px !important;
    box-shadow: 0px 3px 6px $black2;

    .material {
      text-align: center;
      padding: 0 5px 0;
      height: 50px;
      background-color: $white;
      justify-content: center;
      flex-direction: column;
      display: flex;
    }
  }

  .card-artes:hover {
    box-shadow: 0 0 0 0.2rem $primary;
  }
  .card-artes .card-title {
    background-color: $primary;
    height: 65px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    position: static;
    padding-top: 10px;
    margin-bottom: 0;
    width: 100%;
  }
  .card-artes .card-title span {
    color: $white;
    font-size: 20px;
    position: static;
    margin: 0;
    line-height: 1px;
    justify-content: center;
  }
  .card-artes .card-body {
    padding-bottom: 0;
    padding-top: 0;
  }
  .card-artes .card-body .row a {
    font-size: 12px;
    line-height: initial;
  }
  .card-artes .card-text {
    background-color: $white;
    height: 60px;
    position: static;
    padding-top: 11px;
    font-size: 20px;
  }

  .card-artes .img-card {
    span {
      color: $gray;
      background-color: $white;
      align-self: center;
      padding-right: 10px;
      padding-top: 5px;
      text-align: center;
      width: 100%;
      position: relative;
    }
    display: flex;
    flex-direction: column;
    max-width: 100%;
    height: 170px;
  }
  .card-artes .img-card .card-img-top {
    position: static;
    max-width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 10px;
  }
  .card-artes .card-body .btn-primary {
    padding: 5px 10px 5px 10px;
    max-width: 100px;
    min-width: 110px;
    background: $primary 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px $black2;
    border-radius: 30px;
    opacity: 1;
    margin-top: auto;
    margin-bottom: auto;
    max-height: 40px;
    padding: 10px;
  }
  .card-artes .card-body .btn-primary:hover {
    background-color: $primary-hover;
    border-color: $primary-hover;
  }
  .card-artes .card-body .btn-primary span {
    margin-left: 3px;
  }

  .card-artes .card-body .col-md-auto {
    padding: 5px;
  }
}
@media only screen and (max-width: 992px) {
  .card-artes .card-text {
    padding-top: 0 !important;
  }

  .card-artes .card-body .col-md-auto {
    display: none;
  }

  .card-artes .card-body .btn-primary {
    width: 100%;
    margin-bottom: 5px;
  }
}
