@import '../../../themes/variables.scss';

.content-column {
  .info-message {
    width: 1024px;
    text-align: center;
    font-size: 24px;
    margin: 40px auto;
    font-weight: 500;
  }

  .box-buttons {
    width: 1024px;
    margin: 30px auto;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      border: none;
      background-color: $gray-light;
      margin: 10px;
      font-weight: 500;
      text-transform: uppercase;
      padding: 10px 15px;
      border-radius: 10px;
      outline: none;
    }
  }

  .box-button-none-print {
    width: 1024px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      border: none;
      background-color: red;
      margin: 10px;
      font-weight: 500;
      text-transform: uppercase;
      padding: 10px 15px;
      border-radius: 10px;
      outline: none;
      color: $white;
    }
  }

  .buttons-navigation {
    display: flex;
    max-width: 1024px;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 40px;
    justify-content: space-between;

    button {
      width: 200px;
      height: 62px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      &:nth-of-type(1) {
        span {
          display: flex;
          &:nth-of-type(1) {
            flex: 1;
            font-size: 1.5rem;
            justify-content: flex-end;
          }
          &:nth-of-type(2) {
            flex: 3;
            justify-content: center;
          }
        }
      }
      &:nth-of-type(2) {
        span {
          display: flex;
          &:nth-of-type(1) {
            flex: 3;
            justify-content: center;
          }
          &:nth-of-type(2) {
            flex: 1;
            font-size: 1.5rem;
            justify-content: center;
          }
        }
      }
    }
  }
}
