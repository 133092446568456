@import '../../../themes/variables.scss';

.header-login {
  width: 100%;
  height: 60px;
  background-color: $primary-selecionado;
  // background-image: linear-gradient($white, $white);
  display: flex;

  @media screen and (min-width: 321px) {
    height: 90px;
  }

  .content-brand {
    img {
      margin-top: 7px;
      margin-left: 10px;
      max-width: 95px;

      @media screen and (min-width: 321px) {
        max-width: 140px;
      }

      @media screen and (min-width: 993px) {
        margin-left: 100px;
      }
    }
  }
}
