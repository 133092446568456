@import '../../../../themes/variables';

.export-estilo * {
  box-shadow: none !important;
}

.export-estilo{
  background-color: rgb(225, 225, 225);
}

.wrapper-tabs-graficos {
  // background-color: $gray-light;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .tabs {
    margin-top: 10px;
    background-color: #ededed;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .container-tab {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;

    margin-bottom: 30px;

    .row-graficos {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      gap: 10px;
      // min-height: 300px;
      max-height: 300px;
    }

    .column-graficos {
      display: flex;
      flex-direction: column;
      gap: 10px;
      flex: 1;
    }
  }
}
