@import '../../../../themes/variables.scss';

.wrapper-content-solicitacaofluxo-aprovar-arte {
  min-height: calc(100vh - 120px);

  @media screen and (min-width: 322px) {
    min-height: calc(100vh - 140px);
  }

  .content-solicitacaofluxo {
    padding: 30px;
    border-bottom: 1px solid $gray-light;
    justify-content: center;
    width: 1024px;
    margin: 0 auto;

    button {
      border-radius: 10px;
      font-size: 18px;
      text-transform: uppercase;
      padding: 9px 30px;
      border: none;
    }

    .table-precos-alterados {
      font-size: 14px;

      th {
        font-weight: 400;
        padding-right: 40px;
      }

      td {
        font-weight: 200;
        padding-right: 40px;
      }
    }
  }
}
