@import '../../../themes/variables.scss';
@import '../../../themes/mixins.scss';

.header-app {
  width: 100%;
  height: 60px;
  background-color: $primary-selecionado;
  // background-image: linear-gradient($secondary, $secondary);
  display: flex;

  @media screen and (min-width: 321px) {
    height: 70px;
  }

  .brand-app {
    flex: 4;
    display: flex;
    justify-content: center;

    .text-mais-imagens {
      color: $white;
      font-size: 1rem;
      margin: 10px;
      border-left: 4px solid white;
      padding-left: 10px;
    }

    img {
      max-width: 100px;
      margin-top: 7px;
    }

    @media screen and (min-width: 993px) {
      flex: 3;
      justify-content: inherit;

      img {
        max-width: 100px;
        margin-top: 7px;
      }
    }
  }
}
