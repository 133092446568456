@import '../../themes/variables.scss';
@import '../../themes/mixins.scss';

.page-without-header {
  .wrapper-page {
    margin: 2% auto;
    max-width: 800px;
    padding: 0 25px;
    position: relative;
  }
}

@media screen and (min-width: 993px) {
  .page-without-header {
    .footer {
      position: absolute;
      bottom: 0;
    }
  }
}
