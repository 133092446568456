@import '../../../../themes/variables.scss';

.wrapper-content-pedido-solicitacoes {
  .content-pedido-solicitacoes {
    position: relative;
    min-height: calc(100vh - 120px);
    max-width: 1280px;
    margin: auto;

    .btn-primary {
      border-radius: 10px;
      margin-top: 20px;
    }

    @media screen and (min-width: 322px) {
      min-height: calc(100vh - 140px);
    }
  }
  @media screen and (max-width: 600px) {
    .content-pedido-solicitacoes {
      .row {
        flex-direction: column;

        .col-lg-3,
        .col-lg-9 {
          max-width: 100%;
        }
      }

      .btn-avancar-voltar {
        justify-content: center;
        flex-direction: column;
        display: grid;
      }
    }
  }
}

.solicitacao-panel {
  .solicitacao-panel-header {
    background: $primary;
    color: $white;
    border-radius: 10px 10px 0 0;
    text-align: center;
    padding: 10px;
  }

  .solicitacao-panel-body {
    border: 1px solid $gray-light;
    border-radius: 0 0 10px 10px;
    padding: 15px;
    .btn-material {
      border-radius: 10px;
      color: $white !important ;
    }
  }

  .table-search-produtos-wrapper {
    height: 500px;
    overflow: auto;

    /* Set header to stick to the top of the container. */
    thead tr th {
      position: sticky;
      top: 0;
      background: $white;
      border-top: $white;
    }

    .table-search-produtos tr {
      font-size: 14px;
      font-family: sans-serif;
    }
  }

  .grid-solicitacao-produtos {
    padding: 0 15px;
    font-weight: 200;
    font-size: 14px;

    .grid-row-produto {
      border-top: 1px solid $gray-light;
      padding: 0px 5px 5px 0px;
      .image-container {
        text-align: center;
        img {
          max-height: 255px;
        }
      }
    }

    .grid-row-produto:nth-child(even) {
      background-color: $white;
    }
    .grid-row-produto:nth-child(odd) {
      background-color: $gray-light;
    }

    label {
      display: block;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 500;
    }

    .produto-error {
      border: 1px solid $danger;
    }
  }

  .solicitacao-wizard-form {
    text-align: center;
    position: relative;
    margin-top: 20px;
    z-index: 1;

    .card {
      z-index: 0;
      border: none;
      border-radius: 0.5rem;
      position: relative;
    }

    .wizard-progressbar {
      margin-bottom: 30px;
      overflow: hidden;
      color: $gray-light;
    }

    .wizard-progressbar .active {
      color: $black;
    }

    .wizard-progressbar li {
      list-style-type: none;
      font-size: 12px;
      float: left;
      position: relative;

      .wizard-progressbar-container-icon {
        position: absolute;
        top: 0;
        width: 50px;
        height: 50px;
        left: calc(50% - 25px);
        align-items: center;
        justify-content: center;
        display: flex;
        color: $gray;
        cursor: pointer;
      }
    }

    .wizard-progressbar li:before {
      content: '';
      width: 50px;
      height: 50px;
      line-height: 45px;
      display: block;
      font-size: 18px;
      color: $white;
      background: $gray-light;
      border-radius: 50%;
      margin: 0 auto 10px auto;
      padding: 2px;
    }

    .wizard-progressbar li:after {
      content: '';
      width: 100%;
      height: 2px;
      background: $gray-light;
      position: absolute;
      left: 0;
      top: 25px;
      z-index: -1;
    }

    .wizard-progressbar li.active:before,
    .wizard-progressbar li.active:after {
      background: $primary-light;
    }

    .wizard-progressbar li.active {
      .wizard-progressbar-container-icon {
        color: $black;
      }
    }

    .fit-image {
      width: 100%;
      object-fit: cover;
    }
  }

  .container-forma-pagamento img {
    display: block;
  }

  .container-forma-pagamento label {
    padding-right: 20px;
    text-align: center;
  }

  .solicitacao-rodape {
    border: 1px solid $gray-light;
    border-radius: 3px;
    background: $gray-light;
  }

  .img-rodape {
    max-width: 100%;
    max-height: 250px;
    min-height: 20px;
  }

  .label-checkbox {
    font-weight: normal !important;
  }

  .campo-obrigatorio {
    border: 1px solid $danger !important;
    border-radius: 0.25rem;
  }

  .tamanho-error {
    border: 1px solid $danger !important;
    border-radius: 0.25rem;
  }
}
