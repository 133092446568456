@import '../../../../../../themes/variables';

.apexcharts-tooltip {
  position: absolute;
  z-index: 9999;
  .arrow_box {
    // position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding: 10px;
    font-family: $font-family;
    span {
      font-size: 0.9rem;
      color: $gray;
      font-weight: lighter;
    }
    #data {
      color: #0000ff;
    }
  }
}

.grafico {
  overflow: visible;
  border-radius: 20px;
  border: 0px solid $black !important;
  background-color: $gray-lighter !important;
  box-shadow: 0px 3px 6px $black2;
  transition: 0.1s;

  flex: 1;

  display: flex;
  flex-direction: column;

  span {
    font-size: 1.3rem;
    font-weight: bolder;
    color: #000000;
  }
}

.grafico:hover {
  box-shadow: 0 0 0 0.2rem $theme-color-primary;
  .grafico-titulo {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
}

.grafico-titulo {
  background-color: $theme-color-primary;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 40px;
  padding: 10px;
  margin-bottom: 0;
  width: 100%;
  text-align: left;
  font-style: $font-family;

  span {
    color: $white;
    font-size: 1rem;
    position: static;
    margin: 0;
    justify-content: center;
    word-wrap: break-word;
  }
}

.grafico-content {
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.grafico-sem-dados {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  font-family: $font-family;
  span {
    font-size: 1.3rem;
    color: $gray;
    font-weight: lighter;
  }
}

.mapa {
  // padding: 10px;
  max-width: fit-content;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  span {
    font-family: $font-family;
    font-size: 1.1rem;
  }

  .titulo {
    font-weight: bold;
    color: #000000;
  }

  .mapa-container {
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    overflow: hidden;
    background-color: #fff;
    width: 450px;
    height: auto;
    // stroke: #000000; //cor das labels

    .zoom-buttons {
      position: absolute;
      top: 10px;
      left: 10px;
      margin-left: auto;
      display: flex;
      flex-direction: row;
      gap: 5px;
    }

    .tooltip-mapa {
      font-family: $font-family;
      font-style: normal;
      background-color: rgba(255, 255, 255, 0.9);
      padding: 5px;
      border-radius: 3px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      transform: translate(5%, -100%);
      z-index: 9999;
    }
  }

  .legenda-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 10px;
    // background-color: #3cff00;

    span {
      font-size: 12px;
      color: #000000;
      font-weight: bold;
    }
    .legenda {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0;
      // background-color: #808089;
      width: 100%;
      .escala {
        width: 100%;
        height: 10px;
        border-style: solid;
        border-width: 1px;
        border-color: #aca899;
      }
      .um {
        background-color: #89afc9;
      }
      .dois {
        background-color: #4990c0;
      }
      .tres {
        background-color: #7fce9a;
      }
      .quatro {
        background-color: #30ca66;
      }
      .gradiente-custo {
        margin-right: 10px;
        margin-left: 10px;
        background: linear-gradient(
          90deg,
          #30ca60 0%,
          #d2d2d1 50%,
          #324977 100%
        );
      }
      .gradiente-participacao {
        margin-right: 10px;
        margin-left: 10px;
        background: linear-gradient(
          90deg,
          #d4d4d4 0%,
          #c9ffdc 50%,
          #30ca67 100%
        );
      }
    }
  }
}

.tabela-container {
  overflow-y: auto;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.tabela {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  min-height: 200px;
  th,
  td {
    border: 1px solid #ddd;
    font-size: 0.9rem;
    padding: 2px;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
    color: $black;
    text-align: center;
  }

  .fixed-header {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .fixed-header-2 {
    position: sticky;
    top: 26.61px;
    z-index: 1;
  }

  .fixed-col-1,
  .fixed-col-2,
  .fixed-col-3 {
    background-color: #f2f2f2;
    position: sticky;
    z-index: 2;
  }

  .fixed-col-1 {
    left: 0;
  }

  .fixed-col-2 {
    left: 100px;
  }

  .fixed-col-3 {
    left: 200px;
  }

  .fixed-index {
    z-index: 3;
  }

  .celula-total {
    font-weight: bold;
    // color: $black;
  }

  .celula-barra {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    width: 100%;

    .barra {
      height: 15px;
    }

    .barra-valor {
      margin-left: 4px;
      font-size: 0.8rem;
      font-weight: normal;
      color: $gray;
      text-align: left;
    }
  }

  .celula-barra-ponto {
    width: 90%;
    position: relative;

    .barra-celula {
      position: absolute;
      background-color: #a1a1a1;
      transform: translateY(-50%);
      height: 6px;
      z-index: 1;
    }

    .ponto-celula {
      position: absolute;
      transform: translateY(-50%);
      background-color: #1f77b4;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      z-index: 2;
    }
  }
}

.scrollable {
  min-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.scrollable-horizontal {
  overflow-y: hidden;
  overflow-x: scroll;
}

.scrollable,
.tabela-container,
.scrollable-horizontal {
  /* width */
  &::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #5555553e;
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #6363636e;
  }
}

#grafico-regional,
#grafico-regional-solicitante {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  max-height: 100%;
  position: absolute;
  transition: 1s ease transform;
  left: 25%;
}

#grafico-regional {
  background-color: #fafafa;
  z-index: 3;
}
#grafico-regional.regional-ativo {
  transform: translateX(-50%);
  transition: 1s ease transform;
}

#grafico-regional-solicitante {
  background-color: #f8f8f8;
  z-index: 1;
}
#grafico-regional-solicitante.solicitante-ativo {
  transition: 1.1s ease transform;
  transform: translateX(50%);
  z-index: 1;
}
