@import '../../../../themes/variables.scss';

.wrapper-content-pedido-cliente {
  .content-pedido-cliente {
    .content-cliente-search {
      #busca,
      .busca,
      .row,
      .d-flex {
        flex-direction: row;
        display: flex;
      }
    }
  }

  .row {
    justify-content: space-between;
  }
}
@media only screen and (max-width: 485px) {
  .wrapper-content-pedido-cliente {
    .content-pedido-cliente {
      .content-cliente-search {
        #busca,
        .busca,
        .row,
        .d-flex {
          flex-direction: column !important;
          display: flex !important;
          align-items: center;

          .col-lg-5 {
            width: 100% !important;
          }
        }
      }
    }

    .display-client {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .row {
      display: flex;
      flex-direction: column;
      align-items: center;
      div {
        margin-bottom: 10px;
      }
    }
  }
}
.modal-busca-loja {
  .modal-dialog {
    max-width: 1000px;
  }

  .modal-header {
    background-color: $primary;
    color: $white;
    text-transform: uppercase;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;

    .modal-title {
      text-align: center;
      width: 100%;
    }

    button span {
      color: $white;
    }
  }

  .modal-body {
    min-height: 200px;
    max-height: 500px;
    overflow: auto;
  }

  .modal-footer {
    text-align: right;

    .button-close {
      border-radius: 10px !important;
      min-width: 120px;
      font-size: 16px;
    }
  }

  .tr-selecao-loja {
    cursor: pointer;
  }
  .tr-selecao-loja:hover {
    background-color: $primary-light !important;
  }

  th {
    font-weight: 900;
    border-top: none;
  }
  .tr-selecionado {
    background-color: $primary !important;
    color: $white !important;
  }
}

.wrapper-content-pedido-cliente {
  position: relative;
  min-height: calc(100vh - 120px);
  max-width: 1024px;
  margin: auto;

  @media screen and (min-width: 322px) {
    min-height: calc(100vh - 140px);
  }

  .content-cliente-search {
    padding: 40px 0;

    label {
      font-size: 1.5rem;
      margin-left: 15px;
      font-weight: 500;
      color: $gray;
    }
  }

  .display-client {
    display: flex;
    margin: 0 auto;
    border-top: 1px solid $gray;
    padding: 20px 0;
    > div {
      flex: 1;
      label {
        color: $gray;
        margin: 0;
        font-weight: bold;
      }
      span {
        margin-left: 5px;
        border: none;
        outline: none;
        text-indent: 10px;
        color: $gray;
        background-color: transparent;
      }
    }
    .set-settings-brand {
      input {
        margin-left: 20px;
        margin-right: 10px;
        &:nth-of-type(1) {
          margin-left: 0;
        }
      }
    }

    .msg-logo-padrao {
      width: 100%;
      color: $white;
      background-color: $primary;
      padding: 3px;
      margin-top: 15px;
    }

    .img-logo {
      margin-top: 20px;
      max-width: 100%;
      max-height: 250px;
    }
  }
}
