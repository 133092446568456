@import '../../../../../themes/variables.scss';
@import '../../../../../themes/placeholders.scss';

.content-historico {
  text-align: left;

  .historico-item {
    label {
      display: block;
    }

    .item-data {
      font-weight: 200;
      font-style: italic;
      font-size: 14px;
    }

    pre {
      border: 1px solid $gray-light;
      border-radius: 3px;
      padding: 10px;
    }

    hr {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}
