@import '../../../../../../../src/themes/variables';

.card-wrapper {
  margin-top: 10px;
  // margin-left: 10px;
  // margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;

  .card {
    padding: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

    box-shadow: 0px 3px 6px $black2;
    min-height: 80px;
    // max-width: 270px;
    max-width: 350px;
    border-radius: 20px;
    background-color: $theme-color-primary;

    color: white;
    font-family: $font-family;

    .card-title{
      font-size: 1rem;
      font-weight: 400;
    }

    .card-data{
      // font-family: Calibri;
      font-size: 1.4rem;
      font-weight: 900;
    }

    p {
      // font-size: 1rem;
      // font-weight: 400;
      margin: 0;
      padding: 0;
    }
  }
}
