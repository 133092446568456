@import '../../../themes/variables.scss';
@import '../../../themes/mixins.scss';

.header-logo-only {
  width: 100%;
  height: 90px;
  background-color: $primary;
  background-image: linear-gradient($secondary, $secondary);
  display: flex;
  text-align: center;

  @media screen and (min-width: 321px) {
    height: 90px;
  }

  .content-brand {
    margin: 0 auto;

    img {
      margin-top: 26px;
      max-width: 95px;

      @media screen and (min-width: 321px) {
        max-width: 220px;
      }
    }
  }
}
