@import '../../../themes/variables.scss';

.modal-busca-loja {
  .modal-dialog {
    max-width: 1000px;
  }

  .modal-header {
    background-color: $primary;
    color: $white;
    text-transform: uppercase;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;

    .modal-title {
      text-align: center;
      width: 100%;
    }

    button span {
      color: $white;
    }
  }

  .modal-body {
    min-height: 200px;
    max-height: 500px;
    overflow: auto;
  }

  .modal-footer {
    text-align: right;

    .button-close {
      border-radius: 10px !important;
      min-width: 120px;
      font-size: 16px;
    }
  }

  .tr-selecao-loja {
    cursor: pointer;
  }
  th {
    font-weight: 900;
    border-top: none;
  }
  .tr-selecionado {
    background-color: $primary !important;
  }
}

.wrapper-content-choose-the-customer {
  min-height: calc(100vh - 120px);

  @media screen and (min-width: 322px) {
    min-height: calc(100vh - 200px);
  }

  .content-choose-customer {
    display: flex;
    padding: 30px;
    justify-content: center;
    max-width: 1024px;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20px;

    > div {
      label {
        font-size: 1.5rem;
        margin-left: 15px;
        font-weight: 500;
        color: $gray;
      }
      &:nth-of-type(1) {
        flex: 3;
        > div {
          display: flex;
          input {
            width: 100%;
            height: 55px;
            border: 1px solid $primary;
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
            border-right: none;
            outline: none;
            text-indent: 20px;
            background-color: transparent;
          }
        }
      }

      &:nth-of-type(2) {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.7rem;
        color: $gray;
        padding-top: 20px;
      }

      &:nth-of-type(3) {
        flex: 3;
        > div {
          display: flex;
          input {
            width: 100%;
            height: 55px;
            border: 1px solid $primary;
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
            border-right: none;
            outline: none;
            text-indent: 20px;
            background-color: transparent;
          }
        }
      }

      .icon {
        background-color: $primary;
        width: 65px;
        height: 55px;
        display: flex;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        justify-content: center;
        align-items: center;
        color: $white;
        font-size: 1.3rem;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .display-client {
    display: flex;
    max-width: 1024px;
    width: 90%;
    margin: 0 auto;
    border-top: 1px solid $gray;
    padding: 20px 0;
    > div {
      flex: 1;
      label {
        color: $gray;
        margin: 0;
        font-weight: bold;
      }
      span {
        margin-left: 5px;
        border: none;
        outline: none;
        text-indent: 10px;
        color: $gray;
        background-color: transparent;
      }
    }
    .set-settings-brand {
      input {
        margin-left: 20px;
        margin-right: 10px;
        &:nth-of-type(1) {
          margin-left: 0;
        }
      }
    }

    .msg-logo-padrao {
      width: 100%;
      color: $white;
      background-color: $primary;
      padding: 3px;
      margin-top: 15px;
    }

    .img-logo {
      margin-top: 20px;
      max-width: 100%;
      max-height: 250px;
    }
  }
  .buttons-navigation {
    display: flex;
    max-width: 1024px;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 40px;
    justify-content: space-between;

    button {
      width: 200px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      &:nth-of-type(1) {
        span {
          display: flex;
          &:nth-of-type(1) {
            flex: 1;
            font-size: 1.5rem;
            justify-content: flex-end;
          }
          &:nth-of-type(2) {
            flex: 3;
            justify-content: flex-start;
          }
        }
      }
      &:nth-of-type(2) {
        span {
          display: flex;
          &:nth-of-type(1) {
            flex: 3;
            justify-content: center;
          }
          &:nth-of-type(2) {
            flex: 1;
            font-size: 1.5rem;
            justify-content: center;
          }
        }
      }
    }
  }
}
